const rules = {
  required: (value) => !!value || 'This field is required.',
  arrayNotEmpty: (value) => value.length !== 0 || 'This field is required.',
  objectRequired: (value) => Object.keys(value).length !== 0 || 'This field is required.',
  validContainerNumber: (v) => /^[A-Za-z]{3}[Uu]\d{7}$/.test(v) || 'Invalid container number format.',
  alphaNumeric: (value) => !value || /^[a-z0-9]+$/i.test(value) || 'Only letters and numbers are allowed. Please remove any spaces or special characters.',
};

export default {
  rules,
};
